import { useContext } from "react";
import { DialogContext } from "../context/DialogContext";
import { IDialogContextData } from "../types";

export const useDialog = (): IDialogContextData => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error("DialogProvider is required");
  }

  return context;
};
