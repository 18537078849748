import {
  Checklist1 as Checklist,
  Clipboard,
  Shield2 as Shield,
  Users,
} from "@admin-portal-shared-components/icons";
import {
  useAnalyticsService,
  useGetEnvPropPerCluster,
  useHasPermission,
  useSidebar,
} from "admin-portal-shared-services";
import React, { useMemo } from "react";
import Router from "../../Router";

export const menuIcons = {
  clipboard: Clipboard,
  checklist: Checklist,
  users: Users,
  shield: Shield,
};

export const AppConfig = (): JSX.Element => {
  const analyticsService = useAnalyticsService();
  const segmentKey = String(useGetEnvPropPerCluster("segmentKey"));
  const hasUserReadPermission = useHasPermission("IdentityFE.User.Read");
  const hasUserWritePermission = useHasPermission([
    "IdentityFE.User.Write",
    "Identity.User.Write",
  ]);
  const hasPOCReadPermission = useHasPermission("IdentityFE.POC.Read");
  const hasPermissionToBatchActions = useHasPermission(
    "IdentityFE.BatchAction.Write"
  );
  const hasPermissionWrite = useHasPermission("IdentityFE.Permission.Write");
  const hasPermissionRead = useHasPermission("IdentityFE.Permission.Read");

  analyticsService.load(segmentKey);

  const memoListMenuWithAccess = useMemo(
    () =>
      [
        {
          id: "1",
          title: "Accounts",
          icon: menuIcons.clipboard,
          path: "/accounts",
          hasAccess: hasPOCReadPermission,
        },
        {
          id: "2",
          title: "Users",
          icon: menuIcons.users,
          path: "/users",
          hasAccess: hasUserReadPermission || hasUserWritePermission,
        },
        {
          id: "3",
          title: "Bulk actions",
          icon: menuIcons.checklist,
          path: "/users/bulk-actions",
          hasAccess: hasPermissionToBatchActions,
        },
        {
          id: "4",
          title: "Roles & Permissions",
          icon: menuIcons.shield,
          path: "/permissions",
          hasAccess: hasPermissionWrite || hasPermissionRead,
        },
      ].filter((listItem) => listItem.hasAccess),
    [
      hasPOCReadPermission,
      hasPermissionRead,
      hasPermissionToBatchActions,
      hasPermissionWrite,
      hasUserReadPermission,
      hasUserWritePermission,
    ]
  );

  const memoSidebarParams = useMemo(
    () => ({
      items: memoListMenuWithAccess,
      utils: [],
    }),
    [memoListMenuWithAccess]
  );

  useSidebar(memoSidebarParams);

  return <Router />;
};
