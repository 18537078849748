import React, { useCallback, useMemo, useState } from "react";
import { Toast } from "../Toast";
import { TOAST_POSITION, TOAST_SWIPE_DIRECTION, ToastProps } from "../types";
import { ToastContext } from "./ToastContext";
import { SetToastOptionsParams, ToastProviderProps } from "./types";

export const ToastProvider = ({
  children,
}: ToastProviderProps): JSX.Element => {
  const [currentOptions, setCurrentOptions] = useState<ToastProps>({
    message: "",
    position: TOAST_POSITION.TOP,
    swipeDirection: TOAST_SWIPE_DIRECTION.UP,
  });

  const setToastOptions = useCallback((options: SetToastOptionsParams) => {
    setCurrentOptions((previousOptions) => ({
      ...previousOptions,
      ...options,
      cacheId: Date.now(),
    }));
  }, []);

  const providedValue = useMemo(
    () => ({ toastOptions: currentOptions, setToastOptions }),
    [currentOptions, setToastOptions]
  );

  return (
    <>
      <Toast {...currentOptions} />
      <ToastContext.Provider value={providedValue}>
        {children}
      </ToastContext.Provider>
      ;
    </>
  );
};
