import React, { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Spinner } from "supplier-portal-shared-components";

const PermissionsPage = lazy(() => import("./pages/Permissions/Permissions"));

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          path="/"
          element={<Navigate to="/permissions" replace />}
        />
        <Route
          path="/permissions"
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <PermissionsPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
