import { ScopeType } from "./associations.types";

export enum AuthorizationTypes {
  "APP" = "APP",
  "GROUP" = "GROUP",
  "ROLE" = "ROLE",
  "SCOPE" = "SCOPE",
}

export interface IGetAssociationParams {
  app: string;
  level: AuthorizationTypes;
  type: AuthorizationTypes;
  value: string;
  tree?: boolean;
  showDescription?: boolean;
}

export interface IGetGroupsParams {
  app?: string;
  name?: string;
  returnUsersFromGroup?: boolean;
}

type IGroup = {
  app: string;
  description: string;
  name: string;
  users: string[];
};
export interface IResponseGetGroups {
  groupList: IGroup[];
}

export interface Link {
  name: string;
  type: string;
  description?: string;
  links: Link[];
}

export interface IRolesPermissions {
  name: string;
  type: string;
  links: Link[];
}

export interface IScopes {
  app: string;
  object: string;
  action: string;
  description?: string;
  type: ScopeType;
}
export interface IDeleteScopeParams {
  action: string;
  app: string;
  object: string;
}

export interface IScopeData {
  scopeList: IScopes[];
}

export interface IScopePayload {
  app: string;
  role: string;
  scope: string;
}

export interface IRolePayload {
  app: string;
  description: string;
  name: string;
}

export interface ICreateScopePayload {
  action: string;
  app: string;
  description?: string;
  object: string;
}

export enum RoutesAssociationTypes {
  "apps" = "apps",
  "groups" = "groups",
  "roles" = "roles",
  "scopes" = "scopes",
}

type PayloadAssociationTypeAttr =
  | { type: RoutesAssociationTypes.apps; name: string }
  | { type: RoutesAssociationTypes.groups; app: string; name: string }
  | { type: RoutesAssociationTypes.roles; app: string; name: string }
  | {
      type: RoutesAssociationTypes.scopes;
      action: string;
      object: string;
      app: string;
    };

export type IUpdateAssociationPayload = {
  description: string;
} & PayloadAssociationTypeAttr;

export type GetScopesParams = {
  app: string;
  action?: string;
  object?: string;
};
