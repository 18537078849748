import { useContext } from "react";
import { ToastContext } from "../context/ToastContext";
import { ToastContextType } from "../context/types";

export const useToast = (): ToastContextType => {
  const toastContext = useContext(ToastContext);

  if (!toastContext) {
    throw new Error("useToast must not be used without ToastProvider");
  }

  return toastContext;
};
