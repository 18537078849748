import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { PropsPerClusterProvider } from "admin-portal-shared-services";
import React from "react";
import { AppConfig } from "./components/AppConfig";
import { DialogProvider } from "./components/Dialog";
import { ToastProvider } from "./components/Toast";
import { AssociationProvider } from "./contexts/Association";
import { globalStyles } from "./globalStyles";
import { IntlProvider } from "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

type AppProps = {
  segmentKey: Record<"default" | "Restricted_US", string>;
};

export default function App(appProps: AppProps): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: "bees-hub-identity-permissions-mfe",
    seed: "bees-hub-identity-permissions-mfe",
  });

  globalStyles();

  return (
    <React.Fragment>
      <PropsPerClusterProvider propValues={appProps}>
        <StylesProvider generateClassName={generateClassName}>
          <QueryClientProvider client={queryClient}>
            <IntlProvider>
              <AssociationProvider>
                <ToastProvider>
                  <DialogProvider>
                    <AppConfig />
                  </DialogProvider>
                </ToastProvider>
              </AssociationProvider>
            </IntlProvider>
          </QueryClientProvider>
        </StylesProvider>
      </PropsPerClusterProvider>
    </React.Fragment>
  );
}
