import { ToastTypes as HexaToastTypes } from "@hexa-ui/components";

export enum TOAST_SEVERITY {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

export enum TOAST_POSITION {
  TOP = "top",
  TOP_RIGHT = "top-right",
  TOP_LEFT = "top-left",
  BOTTOM = "bottom",
  BOTTOM_RIGHT = "bottom-right",
  BOTTOM_LEFT = "bottom-left",
}

export enum TOAST_SWIPE_DIRECTION {
  UP = "up",
  DOWN = "down",
  RIGHT = "right",
  LEFT = "left",
}

export type ToastProps = Omit<HexaToastTypes, "open" | "onOpenChange"> & {
  cacheId?: number;
  label?: string;
  duration?: number;
  swipeDirection?: TOAST_SWIPE_DIRECTION;
};
