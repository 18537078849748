import { Toast as HexaToast } from "@hexa-ui/components";
import React, { useEffect, useState } from "react";
import { ToastProps } from "./types";

export const Toast = ({
  cacheId,
  label,
  duration,
  swipeDirection,
  onClose,
  ...rest
}: ToastProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    onClose?.();
    setIsOpen(false);
  };

  useEffect(() => {
    if (cacheId) setIsOpen(true);
  }, [cacheId]);

  return (
    <HexaToast.Provider
      label={label}
      duration={duration}
      swipeDirection={swipeDirection}
    >
      <HexaToast.Root
        open={isOpen}
        onOpenChange={duration ? setIsOpen : undefined}
        onClose={handleClose}
        style={{ width: "auto" }}
        {...rest}
      />
    </HexaToast.Provider>
  );
};
