import { globalCss, styled } from "@bees/vision-tokens";

export const globalStyles = globalCss({
  "*": {
    margin: 0,
    padding: 0,
    border: 0,
    boxSizing: "border-box",
  },
  body: {
    fontFamily: "$paragraph",
    fontSize: "$2",

    "h1,h2,h3,h4,h5,h6": {
      fontFamily: "$heading",
    },

    "ul, ol": {
      listStyle: "none",
    },
  },
});

export const WrapperPage = styled("div", {
  paddingLeft: "7rem",
  paddingRight: "1rem",
  paddingTop: "2.188rem",
});
