import React, { useCallback, useMemo } from "react";
import {
  TOAST_POSITION,
  TOAST_SEVERITY,
  useToast,
} from "../../../components/Toast";
import { DialogProps, IDialogElement, OpenDialogParams } from "../types";
import { Dialog } from "./../Dialog";
import { DialogContext } from "./DialogContext";

export const DialogProvider = ({ children }: IDialogElement): JSX.Element => {
  const [dialogConfig, setDialogConfig] = React.useState<DialogProps>();
  const { setToastOptions } = useToast();
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const openAlertCloseModal = useCallback(() => {
    setToastOptions({
      position: TOAST_POSITION.TOP_RIGHT,
      type: TOAST_SEVERITY.WARNING,
      showCloseButton: true,
      duration: 8000,
      message: "There are pending changes",
    });
  }, [setToastOptions]);

  const onClose = useCallback(() => {
    setDialogConfig((previousConfigs) => ({
      ...previousConfigs,
      open: false,
      body: null,
      title: null,
      actions: null,
    }));
  }, []);

  const closeDialog = useCallback(() => {
    if (isEditing) return openAlertCloseModal();
    onClose();
  }, [isEditing, onClose, openAlertCloseModal]);

  const cancelDialog = useCallback(() => {
    onClose();
    setIsEditing(false);
  }, [onClose]);

  const openDialog = useCallback(
    (config: OpenDialogParams) =>
      setDialogConfig((previousConfigs) => ({
        ...previousConfigs,
        ...config,
        open: true,
      })),
    []
  );

  const providedValue = useMemo(
    () => ({ openDialog, closeDialog, setIsEditing, cancelDialog }),
    [openDialog, closeDialog, cancelDialog]
  );

  const props: DialogProps = {
    onEscapeKeyDown: closeDialog,
    onInteractOutside: closeDialog,
    onClose: closeDialog,
    ...dialogConfig,
  };

  return (
    <DialogContext.Provider value={providedValue}>
      <Dialog {...props}>{dialogConfig?.body}</Dialog>
      {children}
    </DialogContext.Provider>
  );
};
