import React, { useCallback, useMemo, useState } from "react";
import { sleep } from "../../../helpers/timers/timers";
import { IAssociationItem } from "../../../pages/Permissions/components/SideMenu/types";
import { IAssociations } from "../../../services/AuthorizationService/associations.types";
import {
  AuthorizationTypes,
  IGetAssociationParams,
} from "../../../services/AuthorizationService/types";
import {
  IAssociationProviderProps,
  IFetchDataParams,
  UpdateSelectedItemType,
} from "../types";
import { AssociationContext } from "../userAssociationContext";
import { useBFFService } from "../../../services/bff-service";

export const AssociationProvider = ({
  children,
}: IAssociationProviderProps): JSX.Element => {
  const [associations, setAssociations] = useState<IAssociations>();
  const [associationSelected, setAssociationSelected] =
    useState<IAssociationItem>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const findSelectedItem = (
    associationsData: IAssociations,
    updateItemSelected: UpdateSelectedItemType
  ) => {
    const links = {
      ...associationsData?.links[updateItemSelected.type],
      ...(associationsData?.links.unbound as unknown as IAssociationItem),
    };
    const updatedItem = Object.values(links).find(
      (item: IAssociationItem) => item.name === updateItemSelected.name
    );
    setAssociationSelected(updatedItem);
  };

  const fetchData = useCallback(
    async (
      params: IFetchDataParams,
      updateItemSelected?: UpdateSelectedItemType
    ) => {
      setIsLoading(true);
      const paramsFetchData: IGetAssociationParams = {
        level: AuthorizationTypes.APP,
        type: AuthorizationTypes.APP,
        tree: true,
        showDescription: true,
        ...params,
      };
      try {
        if (updateItemSelected) await sleep(3000);
        const BFFService = useBFFService();
        const associationsData = await BFFService.getAssociations(
          paramsFetchData
        );
        associationsData.links.unbound = await BFFService.getUnbound(
          params.app
        );

        setAssociations(associationsData);
        updateItemSelected &&
          findSelectedItem(associationsData, updateItemSelected);
      } catch (err) {
        setError(err as Error);
      }
      setIsLoading(false);
    },
    []
  );

  const associationsMemo = useMemo(
    () => ({
      associations,
      error,
      isLoading,
      fetchData,
      associationSelected,
      setAssociationSelected,
    }),
    [
      associations,
      error,
      isLoading,
      fetchData,
      associationSelected,
      setAssociationSelected,
    ]
  );
  return (
    <AssociationContext.Provider value={associationsMemo}>
      {children}
    </AssociationContext.Provider>
  );
};
