import { useContext } from "react";
import { IAssociationContextType } from "../types";
import { AssociationContext } from "../userAssociationContext";

export const useAssociation = (): IAssociationContextType => {
  const context = useContext(AssociationContext);

  if (!context) {
    throw new Error(
      "useAssociation must not be used without AssociationProvider"
    );
  }

  return context;
};
