import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  test: {
    missing: "Only English option available",
    translate: "Translate",
  },
};

export default enUS;
